.progress-bar{
    margin-top: 30px;
}
/* .progress-title p{
    color: white;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}
.bar{
    width: 100%;
    height: 13px;
    margin-top: 10px;
    background-color: #9b003c!important;
    border-radius: 10px;
} */
/* .bar-desktop{
    width: 100%;
    height: 10px;
    margin-top: 10px;
    background-color: #9b003c!important;
    border-radius: 10px;
} */
.bar-dtra{
    width: 100%;
    height: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: #00a0ec!important;
    border-radius: 10px;
}
/* progress {
    border-radius: 10px;
  }
  progress::-webkit-progress-bar {
    background-color: white!important;
    border-radius: 10px;
  }
  progress::-webkit-progress-value {
    background-color: #9b003c!important;
    border-radius: 10px 10px 10px 10px;
  }
  progress::-moz-progress-bar {
    background-color: #9b003c!important;
    border-radius: 10px 10px 10px 10px;

  } */
  .bar-dtra::-webkit-progress-bar {
    background-color: #e1f3fa!important;
    border-radius: 10px;

  }
  .bar-dtra{
      margin:0
  }
  .bar-dtra::-webkit-progress-value {
    background-color: #00a0ec!important;
    border-radius: 10px 10px 10px 10px;
  }
  .bar-dtra::-moz-progress-bar {
    background-color: #00a0ec!important;
    border-radius: 10px 10px 10px 10px;

  }
  .help{
    width: 28px;
    height: 20px;
    background-color: #00a1ed;
    color: white;
    font-family: 'Lato', sans-serif;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
}
.help-wrapper{
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    width: 400px;
    /* border:1px solid black; */
    /* height: 100px; */
    padding: 10px;
    right: 30px;
    top: 50px;
    z-index: 2;
}
.help-wrapper p{
    font-size: 12px;
}
.help-backdrop{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.help-mobile{
    padding:10px ;
    /* height: 100px; */
    /* border:1px solid black; */
}
.help-mobile p{
    font-size: 14px;
    font-weight: 600;
}

.spex2-option-item{
    width: 100%;
    /* padding: 10px; */
    background-color: rgba(204, 236, 251, 0.5);
    cursor: pointer;
    margin-bottom: 20px;
}
.spex2-option-item p{
    margin: 0;
}
.selected{
    background-color: #00A1ED;
}
.selected p{
    color: white;

}

.dtra-option-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box{
    box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.2);
    width: 25%;
    padding: 20px;
}





@import url('https://fonts.googleapis.com/css?family=Ibarra+Real+Nova&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
.report-wrapper{
    width: 75%;
    margin:auto;
    font-family: 'Ibarra Real Nova', serif;
}
.report-wrapper p{
    font-family: 'Lato', sans-serif;
    color: #464646;
}
body{
    -webkit-print-color-adjust: exact;
}
.pagebreak { 
    margin-top: 100px;
}
@media print 
{
    @page {
      size: A4; /* DIN A4 standard, Europe */
      margin:0;
    }
    
    .report-wrapper{
        width: 100%;

    }
    
    .report-header,.report-footer{
        width: 100%!important;
        /* z-index: 1030; */
        /* -webkit-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
        /* position: fixed; */
    }
    .report-header{
        margin-bottom: 250px;

    }
    .pagebreak { page-break-before: always; }
    .report-h1-title{
        font-size: 40px!important;
        color: #00a1ed;
    }
    .report-shape4{
        /* height: 40px!important; */
        /* border:1px solid black */
    }
    
}
.report-shape{
    margin: 0px 0px 0px auto;
    width: 600px;
    height: 40px;
    background-image: linear-gradient(to right, #00a1ed, #9b013c);
    border-radius: 0px 0px 0px 100px;
}
.report-shape2{
    margin: 0px 0px 0px auto;
    width: 600px;
    height: 40px;
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    border-radius: 0px 0px 0px 100px;
}
.report-shape3{
    margin: 0px auto 0px 0px;
    width: 95%;
    /* height: 40px; */
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    /* border-radius: 0px 0px 0px 100px; */
}
.report-shape3 h1{
    font-size: 22px;
    color: white;
    padding: 10px 10px 10px 50px;
}
.report-shape4{
    margin: 0px auto 0px 0px;
    width: 100%;
    /* height: 40px; */
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    /* border-radius: 0px 0px 0px 100px; */
}
.report-shape4 h1{
    font-size: 22px;
    color: white;
    padding: 10px 10px 10px 50px;
}
.report-h1-title{
    font-size: 70px;
    color: #00a1ed;
}

.vertical-title{
    color: rgba(155, 1, 60, 0.05);
    font-size: 80px;
    transform: rotate(-90deg);
    position: absolute;
    right: -220px;
    top: 120px;
}
.vertical-text{
    transform: rotate(-90deg);
}
.report-title{
    position: relative;

    /* border:1px solid black; */
    min-height: 150px;
}

.report-information p{
    font-size: 18px;
    /* font-weight: bold; */
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif;
    color: #464646; 
    font-size: 16px;
}
.text-bold{
    font-weight: bold!important;
}
.report-map{
    text-align: center;
}
.report-map img{
    width: 90%;
}
.report-header{
    position: fixed;
    top:0;
    width: 75%;
    /* border:1px solid black; */
    background-color: white;
    z-index: 1;
    margin-bottom: 100px;
}
.report-footer{
    position: fixed;
    bottom:0;
    width: 75%;
    height: 50px;
    background-color: white;
    z-index: 1;
}
.report-footer p{
    font-size: 18px;
    font-weight: bold;
    margin:0;
    font-family: 'Lato', sans-serif;
}

.page2{
    margin-top: 100px;
}

.div-margin{
    width: 90%;
    margin: auto;
}
.div-margin2{
    width: 90%;
    margin: auto;
}
.strategic-text p{
    color:#464646;
    font-size:14px;
    font-family: "Lato",sans-serif;
    /* font-weight: 600; */
}
.pemetaan p{
    margin:10px
}
.div-table p{
    font-size: 14px;
    font-weight: normal;
}
.border-bottom-table{
    border-bottom: 1px solid #eeeeee;
}
.header-space, .footer-space{
    height: 110px;
  }

  .content{
    width: 100%!important;
    
}
.lps-flex{
    width: 100%;
    display:grid;
    grid-template-columns: repeat( 2,1fr);
    grid-row-gap:10
}
.form-80{
    width:80%
}
  @media screen and (max-width:768px){
    .report-header{
        width: 100%;
        /* border:1px solid black; */
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .report-wrapper{
        width: 100%;
    }
    .content{
        margin-top: 20px;
    }
    .report-footer{
        width: 100%;
    }
    .report-shape{
        width: 300px;
        /* border:1px solid black */
    }
    .lps-flex{
        grid-template-columns: repeat( 1,1fr);
    grid-row-gap:3

    }
    .form-80{
        width:100%
    }
    .box{
        box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.2);
        width: 100%;
        padding: 0px;
    }
    .help{
        width: 27px;
        height: 27px;
    }
  }