@import url(https://fonts.googleapis.com/css?family=Ibarra+Real+Nova&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-remove-capital{
  text-transform: none!important;
  font-weight: bold!important;
}
.btn-rounded{
  border-radius: 20px!important;
}
.remove-capital{
  text-transform: none!important;
  font-weight: 600!important;
}
.icon-table{
  width:16px ;
  margin-left: 24px;
  cursor: pointer;
}
.remove-boxshadow{
  font-weight: 600!important;
  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important;
  /* box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important; */

}
.div-flex{
  display: flex;
}
.div-space-between{
  justify-content: space-between;
}
.div-align-center{
  align-items: center;
  /* border:1px solid black; */
}
.div-justify-center{
  justify-content: center;
}
.semi-bold{
  /* font-weight: bold; */
  color: #777777;
  font-size: 18px;
font-weight: 600;
  margin-top:0;
  font-size: 14px;
font-weight: 600;
/* margin:5px; */
}
.semi-bold-without-margin{
  /* font-weight: bold; */
  color: #777777;
  font-size: 18px;
font-weight: 600;
  margin-top:0;
  margin-bottom:0;
  font-size: 14px;
font-weight: 600;
/* margin:5px; */
}
.semi-bold2{
  /* font-weight: bold; */
  color: #777777;
font-weight: 600;
  margin-top:0;
  font-size: 10px!important;
font-weight: 600;
/* margin:5px; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav{
    width:100%;
    height: 64px;
    /* border:1px solid black; */
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    z-index: 999;
    position: fixed;
    top:0;
    align-items: center;
    padding: 0 50px 0 30px;
}
.logo{
    width:250px;
    /* border:1px solid red; */
    align-self: center;
    /* justify-content: space-around; */
    /* align-items: center; */
    display: flex;
    cursor: pointer;
    /* margin-left: 20px; */
}
.logo img{
    width: 100%;
}

.vertical-line{
    width:2px;
    height: 50px;
    background-color: #ccc;
}
.logo img{
    width: 40px;
}
.logo h4{
    color: rgb(128, 128, 128);
    font-size: 20px;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.06px;
}
.profile{
    width: 170px;
    cursor: pointer;
    /* border:1px solid green; */
    /* text-align: center; */
}



.search{
    width: 790px;
    border-radius: 4px;
    align-items: center;
    padding: 2px;
    display: flex;
    border-radius: 34px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: #ffffff;
}
.search img{
    margin-left: 10px;
    margin-right: 10px;
    width: 20px;
}
.search-field{
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.05px
}
@media screen and (max-width:768px){
    .hide-mobile{
        display: none;
    }
    .hamburger{
        display: flex;
    }
    nav{
        height: 100px;
        
    }
    .logo{
        align-content: center;
        border:1px solid black;
    }
}
.sidebar-wrapper{
  width:250px;
  height: 100vh;
  position: fixed;
  background: url(/static/media/sidebar.e7916f66.jpg);
  background-repeat: no-repeat;
  background-size:100% 100%;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 90px;
  /* animation: movingsidebar 0.25s ease-in-out; */
  
}
.sidebar-wrapper-oes{
  width:250px;
  height: 100vh;
  position: fixed;
  background: url(/static/media/oes_bg.ee204f29.png);
  background-repeat: no-repeat;
  /* background-size:100% 100%; */
  background-size: cover;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 90px;
  /* animation: movingsidebar 0.25s ease-in-out; */
  transition: all 0.3s ease-in-out;
}
/* .sidebar-wrapper:after{
  left:0; top:0;bottom:0;
  z-index: -1;
  position: absolute;
  content: '';
  width: 250px;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.8);
} */
.sidebar-wrapper a{
  font-weight: bold;
}
.sidebar-wrapper-oes a{
  font-weight: bold;
  display: block;
}
.expand-sidebar{
  width: 80px!important;
}


.expand-sidebar a{
  display: none;
}
@-webkit-keyframes movingsidebar{
  from{
      width:0px;
  }to{
      width: 250px;
  }
}
@keyframes movingsidebar{
  from{
      width:0px;
  }to{
      width: 250px;
  }
}
/* .list-sidebar a{
  margin: 10px 0px 15px 25px;
  padding:5px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 8px;
} */
.sidebar-wrapper-oes .list-sidebar{
  margin: 0 15px;
}
.sidebar-wrapper-oes .list-sidebar img{
  margin-right: 20px;
}
.list-sidebar{
  margin: 10px 10px 15px 25px;
  /* height: 30px; */
  padding:5px 15px 5px 10px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.sidebar-wrapper .hr{
  /* color: green; */
  width: 230px;
  height: 1px;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: white;
}
.sidebar-wrapper-oes .hr{
  /* color: green; */
  width: 230px;
  height: 1px;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: white;
}
.sidebar-wrapper-oes .is-collapse{
  margin: 0 15px;
  margin-bottom: 15px;
}
.expand-sidebar .is-collapse .chevron{
  display: none;
}
.expand-sidebar .is-collapse .active-chevron{
  display: none;
}
.is-collapse{
  margin: 10px 10px 15px 25px;
  /* height: 30px; */
  padding:5px 15px 5px 10px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.is-collapse:hover{
  color: #f1f1f1;
  background-color: #e1e7ea;
}
.is-active-menu{
  background-color: #e1e7ea;
}
.is-active-menu a{
  color: #00a1ed!important;

}
.admin-list:hover{
  color:#00a1ed!important; ;
}
.content-collapsee{
  width: 70%;
  margin: 0px 0px 15px 75px;
}
.list-collapse a{
  /* margin: 20px 0px 0px 0px; */
  margin-bottom: 5px;
  /* height: 30px; */
  padding:5px 10px 5px 10px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 20px;

}
.list-collapse-active{
  color: #f1f1f1;
  background-color: #e1e7ea;
  border-radius: 8px;
}
.list-sidebar:hover, .list-collapse a:hover{
  color: #00a1ed!important;
  background-color: #e1e7ea;
}
/* .list-sidebar a:hover{
  color: #00a1ed;

} */
.backdrop-wrapper{
  width: 100%;
  height: 100vh;
  /* border:1px solid green */
}
.chevron{
  width: 15px;
  height: 20px;
  transition: 0.70s;
}
.active-chevron{
  width: 15px;
  cursor: pointer;
  transition: 0.70s;
  transform: rotate(180deg);
}

.icon-size{
  width: 25px;
}
.ava-size{
  width: 15px;
}
.rounded-avatar{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #e6e8e6;
}
@media screen and (max-width:768px){
  .sidebar-wrapper{
      /* display: none ; */
      z-index: 3;
  }
  .sidebar-wrapper-oes{
      /* display: none ; */
      z-index: 3;
  }
}

.login-wrapper{
    height: 95vh;
    border:1px solid white;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:80%;
    margin: auto;
}
.login-form{
    width:40%
    /* width: 400px;
    height: 430px;
    margin-left: 99px;
    margin-top: 120px; */
}
.img-bg{
    width: 50%;
}
.img-bg img{
    width:100%
}
.login-form h1{
    color: #65b7ff;
    font-family: 'Open Sans', sans-serif;

}
.login-form p{
    color: #808080;
}
.login-form h3{
    font-size: 24px;
    color: #808080;
    margin-top: 10px;
}
.form-login{
    margin-bottom: 20px!important;
    width: 260px;
  /* height: 40px!important; */

}
.form-login2{
    margin-bottom: 20px!important;
    width: 100%;
  height: 40px!important;

}
.btn-login{
    font-weight: bold!important;

    text-transform: none!important;
    width:260px;
    border-radius: 24px!important;
    box-shadow: 0 3px 6px 0 rgba(0, 161, 237, 0.25);

}
.img-bg-mobile{
    display: none;
}
@media screen and (max-width:768px){
    .login-wrapper{
    margin-bottom: 50px;

    }
    .img-bg{
        display: none;
    }
    .login-form{
        width: 100%;
        text-align: center;
        margin-top: 80px;
    }
    .form-login{
        width: 100%;
        margin-bottom: 30px;
    }
    .btn-login{
        width: 100%;
        font-weight: bold!important;
    }
    .img-bg-mobile{
        display: block;
        width: 100%;
    }
    .login-form h1{
        font-size: 20px;
        margin-top: 20px;
    }
}
.loading-wrapper{
    position: fixed;
    z-index: 500;
    height: 100vh;
    width: 100%;
    background-color: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcome-wrapper{
    display: flex;
    width: 90%;
    margin: auto;
    height: 99vh;
    align-items: center;
    /* border:1px solid black */
}

.welcome-text{
    /* border:1px solid red; */
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.welcome-example{
    /* border:1px solid blue; */
    width: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.shape-img{
    width: 100px;
    position: absolute;
}

.welcome-text h2{
    font-size: 20px;
    font-weight: normal;
    color: #00a1ed;
}
.welcome-text span{
    font-weight: bold;
}
.welcome-text p{
    color: #808080;
    margin:30px 0px 30px 0px!important;
}

.welcome-example{
    padding: 20px;
    display: flex;
    align-items: center;
}
.card-example{
    width: 100%;
    max-height: 510px;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
    background-color: white;
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;
}
.card-example::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	/* border-radius: 10px; */
	/* background-color: #F5F5F5; */
}

.card-example::-webkit-scrollbar
{
	width: 6px;
	/* background-color: #F5F5F5; */
}

.card-example::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
	background-color: #bdbdbd;
}
.card-example{
    padding: 20px;

}
.card-example h3{
    color: #00a1ed;
}
.card-example p{
    color:#808080;
    font-weight: 500;
}


.card-grid{
    width:100%;
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    
}

.card-grid2{
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    /* overflow-y: hidden;
    overflow-x: auto; */
}
.card-grid>div{
    /* border:1px solid black; */
    /* height: 100px; */
}

.grid-head{
    display: flex;
    justify-content: space-between;
}
.grid-head p{
    margin: 0px 35px 15px 10px;
    font-size: 18px;
}
.grid-head{
    display: flex;
    justify-content: space-between;
}
.grid-head p{
    margin: 0px 35px 15px 10px;
    font-size: 18px;
}
.grid-head2 p{
    margin: 0px 35px 15px 10px;
    font-size: 18px;
}
.grid-head2{
    display: flex;
    justify-content: space-between;
}
.grid-head2 p{
    margin: 0px 20px 15px 10px;
    font-size: 18px;
}

.left-card , .right-card{
    width: 9%;
    min-height: 25px;
    border:1.4px solid #707070;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-card-example , .right-card-example{
    width: 20%;
    min-height: 25px;
    border:1.4px solid #707070;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .left-card */
.middle-card{
    width: 70%;
    min-height: 30px;
    border:1.4px solid #707070;
    display: flex;
    justify-content: center;
    align-items: center;
}
.middle-card-example{
    width: 50%;
    min-height: 25px;
    border:1.4px solid #707070;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-content>div{
    margin: 2px;
}
.grid-content2>div{
    margin: 5px;
}
.middle-card p{
    margin: 3px;
    color: #252525!important;
    font-size: 16px;
    text-align: center;
}
.middle-card-example p{
    margin: 3px;
    
    font-size: 9px;
}
.active-card{
    background-color: #00a1ed;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #00a1ed!important
}
.show-mobile{
    display: none;
}

.navbar-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: #00a1ed;
    position: fixed;
    top:0;
    z-index: 999;
}
.navbar-wrapper h3{
    font-weight: normal;
    color: white;
}
.navbar-wrapper .name{
    font-weight: bold;
}
.navbar-wrapper .timer{
    font-weight: bold;
    color: #ffb865;
}
.timer-expired{
    font-weight: bold;
    color: red;
}
.assestment-wrapper{
    width: 100%;
    margin-top: 100px;
    background: url(/static/media/shape_2.d44366df.svg),url(/static/media/shape_2.d44366df.svg),url(/static/media/shape_2.d44366df.svg),url(/static/media/shape_2.d44366df.svg),url(/static/media/shape_2.d44366df.svg),url(/static/media/shape_2.d44366df.svg),url(/static/media/shape_2.d44366df.svg),url(/static/media/shape_2.d44366df.svg);
    background-repeat: no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat;
    background-position: left 20px,right 300px,left 600px,right 900px,left 1200px,right 1500px,left 1800px,right 2110px;
    /* opacity: 0.3; */
    background-size: 15%;
    background-color: #fcfcfc;
}
.assestment-grid{
    /* margin-top: 500px; */
    width: 35%;
    margin:auto;
    margin: 50px auto 0px auto ;
    display: grid;
    grid-template-columns: repeat( 1,minmax(10rem,1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    z-index: 4;
    opacity: 1!important;
    color: #808080;
    font-weight: 500;
    /* opacity: 1; */
}
.assestment-grid>div{
    min-height: 100px;
    /* border:1px solid black */
}
.square{
    width: 8%;
    height: 28px;
    margin-top: 45px;
    margin-right: 10px;
    border:1.4px solid#808080;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin:5px */
}
.navbar{
    width: 80%;
    margin: auto;
}
.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5000;

    position: fixed!important;
}
.modal-content{
    width:400px;
    outline: 0px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    /* overflow-y: scroll; */
}
.modal-body{
    padding:10px
}
.expired-wrapper{
    text-align: center;
    padding:10px

}

.info-wrapper{
    padding:10px;

}
.info-flex{
    display: flex;
}
.info-wrapper h2{
    color:#00a1ed;
    margin:0;
    margin-bottom: 10px;
}
.info-data-diri{
    width:50%;
}
.info-kontak{
    width:50%;
    padding-left: 20px;
}
.question-flex{
    display: flex;
}
.footer-form-mobile{
    display: none!important;
}

.chevron-contoh{
    width: 15px;
    cursor: pointer;
}
.image-contoh{
    width: 70%;
    
}
.instruksi-text{
    font-size: 16px;
    margin:0
}
.slider-contoh{
    overflow-y: hidden;
    overflow-y: auto;
}
.card-grid-contoh{
    width: 300px;
}
.circle-help{
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
}
.circle-help span{
    font-weight: bold;
    color: #00a1ed;
}
.assestmen-toolbar{
    width: 360px;
}
.assestment-container{
    width: 65%;
    margin:auto;
}
.assestment-container-h4{
    margin:0!important;
    color: #808080;
}
.assestment-container-p{
    margin:0!important;
    color: #808080;
}
.assestment-question{
    display: flex;
    position: relative;
    /* justify-content: center; */
    /* text-align: center; */
}
.circle-number{
    width: 23px;
    height:  23px;
    border:2px solid black;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
}
.assestment-image{
    width: 60%;
}
.assestment-answer{
    display: flex;
    flex-wrap: wrap;
    /* margin-right: 20px; */
    /* gap: 22px!important; */
    /* grid-gap: 21px!important; */
}
.assestment-subimage{
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
}
.assestment-answer-item{
    /* width:115px; */
    text-align: center;
    margin-right: 20px;
    margin-bottom: 20px;
}
.question-wrapper{
    margin-left: 30px;
}
.sub3{
    display: flex;
    align-items: flex-start;
    /* gap:12px; */
    width:100%;
    text-align: center;
}
.sub4{
    display: flex;
    align-items: flex-start;
    /* gap:12px; */
    width:100%;
    text-align: center;
}
.assestment-image-sub3{
    width: 20%;
    margin-right: 20px;
}
.sub3-option-wrapper{
    margin-top: 80px;
}
.assestment-image-sub4{
    width: 90px;
    margin-right: 20px;
}
.sub4-option-wrapper{
    /* margin-top: 80px; */
}

.papi-example-wrapper{
    width: 50%;
    margin: auto;
    
}
.papi-example-checkbox{
    width: 7%;
    border: 1px solid #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}
.papi-example-checkbox img{
    width:25px;
    visibility: hidden;
}
.checked-example{
    background-color: #00a1ed    ;
    border: 0!important;
}
.checked-example img{
    visibility: visible;
}
.papi-example-question{
    cursor: pointer;
    padding: 8px;
    width:93%;
    border: 1px solid #707070;

}
.papi-example-question p{
    margin: 0;
    color: #808080;
}
.papi-question-wrapper{
    width: 70%;
    margin: auto;
    margin-bottom: 25px;
}
.papi-question-number{
    width: 40px;
    height: 40px;
    border: 1px solid #808080    ;
    color: #808080  ;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}
.papi-question{
    display: flex;
    /* grid-gap: 20px!important; */
    margin-bottom: 5px;
    /* border: 1px solid black; */
}
.papi-question-checkbox{
    width: 4.5%;
    border: 1px solid #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    margin-right: 20px;
}
.papi-question-checkbox img{
    width:25px;
    visibility: hidden;
}
.checked-question{
    background-color: #00a1ed    ;
    border: 1px solid  #00a1ed!important;
}
.checked-question img{
    visibility: visible;
}
.papi-question-question{
    cursor: pointer;
    padding: 8px;
    width:93%;
    border: 1px solid #707070;

}
.papi-question-question p{
    margin: 0;
    color: #808080;
}

.toefl-question-wrapper{
    width: 70%;
    margin: auto;
    margin-bottom: 25px;
    /* border: 1px solid black; */
}
.toefl-question-number{
    width: 30px;
    height: 30px;
    border: 1px solid #808080    ;
    color: #808080  ;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    /* margin-bottom: 20px; */
}
.toefl-question-number2{
    width: 28px;
    height: 28px;
    border: 1px solid #808080    ;
    color: #808080  ;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    /* margin-bottom: 20px; */
}
.toefl-question-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.toefl-question-header-reading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.toefl-question-header-reading2{
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    margin-bottom: 10px;
}
.toefl-answer-item{
    /* width: 550px; */
    /* border: 1px solid red; */
    display: flex;
    margin-bottom: 5px;
}
.option-box{
    border: 1px solid #707070    ;
    width: 100%;
    padding: 10px;
}
.option-box p{
    margin: 0;
}
.toefl-question-reading-desc{
    width: 88%;
}
.toefl-question-reading-desc p{
    margin: 0;
    color: #252525;
}
.toefl-question-reading2-desc{
    margin: 0;
    color: #252525;
    font-size: 17px;
    font-weight: 500;
    width: 520px;
}
.st{
    position: relative;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
}
.st-answer{
    border-bottom: 1.5px solid #00a1ed;

}
@media screen and (max-width:768px){
    .toefl-question-reading2-desc{
        width: 80%;
    }
    .toefl-question-header-reading{
        align-items: unset;
    }
    .toefl-question-header-reading2{
        align-items: unset;
        /* justify-content: space-between; */
    }
    .toefl-question-reading-desc{
        width:80%;
    }
    .toefl-question-header{
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: unset;
    }
    .toefl-question-wrapper{
        width: 100%;
    }
    .papi-question-wrapper{
        width: 100%;
    }
    .papi-question-checkbox{
        width: 9%;
    }
    .papi-example-wrapper{
        width: 100%;
    }

    .sub3{
        flex-direction: column;
        justify-content: center;
        margin:0px!important
    }
    .assestment-image-sub3{
        width: 50%;
        margin: auto;
    }
    .sub3-option-wrapper{
        margin-top: 0px;
    }
    .sub4{
        flex-direction: column;
        justify-content: center;
        margin:0px!important
    }
    .assestment-image-sub4{
        width: 50%;
        margin: auto;
    }
    .sub4-option-wrapper{
        margin-top: 0px;
    }
    .assestment-container{
        width: 90%;
    }
    .assestment-container-h4{
        margin-bottom: 10px!important;
    }
    .assestment-container p{
        font-weight: 500;

    }
    .assestment-container-h4{
        font-size: 22px!important;
        color: #808080;
    }
    .assestment-intruksi{
        font-size: 22px!important;
        color: #808080;
    }
    .assestment-question{
        flex-direction: column;
    }
    .question-wrapper{
        margin:40px 0px 0px 0px;
    }
    .circle-number{
        width: 30px;
        height: 30px;
        border:3px solid black;
        font-size: 24px;
    }
    .assestment-image{
        width: 100%;
    }
    .assestment-answer-item{
        width: 29%;
        margin-right: 10px;
    }
    .assestmen-toolbar{
        width: 160px;
        flex-direction: row-reverse;
        margin-right: 10px;
    }
    .instruksi-text{
        font-size: 18px;
    }
    .image-contoh{
        width: 80%;
    }
    .footer-form-mobile{
        display: flex!important;
    }
    .info-wrapper{
        padding:20px
    }
    .question-flex{
        flex-direction: column;
    }
    .assestment-wrapper{
        background: white;
    }
    .info-data-diri{
        width:100%
    }
    .info-kontak{
        width: 100%;
        padding-left: 0px;
    }
    .info-flex{
        flex-direction: column;
    }
    .welcome-wrapper{
        flex-direction: column;
        width: 100%;
        /* border:1px solid black; */
        height: -webkit-fit-content;
    }
    .show-mobile{
        display: block;
    }
    .welcome-text{
        width: 100%;
        min-height: 230px;
        /* border:1px solid red; */

    }
    .welcome-mobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        /* height: 100vh; */
        padding: 10px 20px 10px 20px;
    }
    .welcome-example{
        width: 100%;
        padding: 0px;
        /* border:1px solid green; */
        /* min-height: 750px; */
    }
    .hide-mobile{
        display: none!important;

    }
    .card-example{
        padding:0px;
        border-radius: 25px;
        max-height: unset;
        overflow-y: hidden;
        /* padding: 10px 20px 10px 20px; */
        
    }
    .card-example h3{
        font-size:21px;
    }
    .card-example p{
        font-weight: normal;
    }
    .shape-img{
        display: none;
    }
    .card-grid{
        width:100%;
        /* border:1px solid red; */
        overflow-y: hidden;
        overflow-x: scroll;
        grid-column-gap: 20px;
        grid-template-columns: repeat(3,2fr)
    }
    .card-grid-assestment{
        width: 200px;
    }
    .assestment-grid{
        width: 95%;
        margin: auto;
        grid-template-columns: repeat( 1,minmax(10rem,1fr));

    }
    .navbar-wrapper h3{
        margin: 0;
    }
    .navbar{
        width: 95%;
        justify-content: space-between;
    }
    .modal-content{
        width:100%;
        outline: 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .modal{
        align-items: flex-end;
    }
    .grid-head p{
        margin: 10px 10px 10px 5px;
    }
    .square{
        width: 30px;
        height: 30px;
        margin-top: 0px;
    }
    .welcome-text h2{
        font-size: 24px;
        font-weight: normal;
        color: #00a1ed;
        margin:20px 0px 0px 0px;
    }
    .welcome-text p{
        color: #808080;
        margin:10px 0px 10px 0px!important;
        font-size: 18px;
    }
    .shape-img-oas{
        display: none;
    }
}
.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5000;

    position: fixed!important;
}
.modal-profile{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-radius: 10px;
    z-index: 5000;
    margin-top: 20px;
    margin-right: 20px;
    /* height: 200px; */
    position: fixed!important;
    padding:10px
}
/* .ck-editor__editable:not(.ck-editor__nested-editable) {
    max-height: 400px;
} */
.modal-content{
    /* width:400px; */
    outline: 0px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    

}
.modal-header{
    padding: 10px 20px 0px 20px;
    
    /* height: 50px; */;
    display: flex;
    justify-content: space-between;
    /* border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium; */
}
.modal-header img{
    cursor: pointer;
}
.modal-header h2{
    font-family: 'Lato', sans-serif;
    color: #252525;
    font-size: 20px;
}
.modal-body{
    /* width: 50%; */
    padding-left:20px;
    padding-right:20px;
    padding-top:0px;
    min-height: 100px;
}
.modal-content .modal-hr{
    border:1px dashed  #ccc;
}
.modal__field{
    width: 100%;
}


.profile-container{
    /* border:1px solid black */
    text-align: center;
}
.profile-container h2{
    font-family: Open Sans, sans-serif;
    color: #252525;
    font-size: 18px;
    margin-bottom: 0;
}
.profile-container p{
    font-family: Open Sans, sans-serif;
    color: #777777;
    font-size: 14px;
    margin-top: 0;

}

.rm-filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rm-filter-by{
    display: flex;
    align-items: center;
    margin-top: 0px!important;

}
.rm-filter-by p{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
}
.rm-filter-field{
    width: 170px;
}
.contact-field{
    width: 140px;
}
.modal-footer{
    display: flex;
    justify-content: flex-end;
    padding-bottom:20px
}
.rm-table{
    max-height: 300px;
    overflow-y: scroll;
}
.rm-table::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE and Edge */
  .rm-table {
    -ms-overflow-style: none;
  }

  .confirm-container,.alert-container{
    padding:10px;
    text-align: center;
}
.card-footer{
    display: flex;
    justify-content: flex-end;
    padding:20px
}
.p-filter{
    font-size: 12px;
    margin:0;
    color: #252525;
}
.p-filter2{
    font-size: 12px;
    margin:0;
    color: #777777;
    font-weight: bold;
}
.participant-modal{
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.intruksi-modal{
    max-height: 550px;
    overflow-y: auto;
}
.intruksi-modal h3{
    color: #00a1ed;
    margin-top: 0;
}
.intruksi-modal p{
    color:#808080;
    font-weight: 500;
}
.card-grid-modal{
    width:100%;
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    overflow-y: auto;
    /* display: flex;
    gap: 40px;
    overflow-y: auto; */
}
.template-email-keterangan-header p{
    margin:0;
    padding: 10px 0px 10px 20px;
}
.template-email-keterangan-body p{
    margin:0;
    font-size: 12px;
    padding: 5px 0px 5px 20px;
}

.input-kredit{
    width: 50px !important;
    text-align: center; 
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    border:1px solid #cccccc
}
.input-kredit:focus{
    outline-width: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media screen and (max-width:768px){
    .modal-content{
    /* overflow-y: scroll; */

        /* border:1px solid black; */
        width:100%;
        outline: 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .modal-header h2{
        font-size: 18px;
    }
    .modal{
        align-items: flex-end;
    }
}
.portal-header{
    width: 87%;
    margin: auto;
    height: 100px;
    z-index: 2;
    position: relative;
    /* border:1px solid black; */
}

.portal-shape{
    width: 100%;
    position: absolute;
    /* z-index: -1; */
    /* height: 40%; */
}
.shape-top{
    top: -50px;
}
.shape-bottom{
    bottom: -50px;
}
.portal-logo{
    width: 150px;
    padding: 20px 0px 20px 0px;
    /* padding: 20px; */
}

.portal-body{
    width: 87%;
    margin: auto;
    z-index: 2000;
    /* border:1px solid black; */
}
.portal-body-h2,.portal-body-p{
    margin: 5px;
}
.portal-body-h2{
    font-family: 'Ibarra Real Nova', serif;
    color: #252525!important;

}
.portal-body-p{
    font-size: 14px;
    color: #444444;

}

.portal-card-wrapper{
    display: inline-flex;
    flex-wrap: wrap;
    /* gap: 30px; */
    margin-top: 140px;
}

.portal-card{
    width: 250px;
    height: 330px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    /* padding: 10px; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.portal-card img{
    width: 90%;
    position: absolute;
    top: -80px;
    padding: 15px;
}
.portal-card-content{
    margin-top: 90px;
    padding: 20px 20px 0px 20px;
    /* height: 100%; */
}
.portal-card-footer{
    padding: 0px 20px 20px 20px;
    /* position: absolute; */
    /* bottom: 0; */
    /* width: 100%; */
}
.portal-card-content h1{
    font-size: 18px;
    margin:10px 0px 10px 0px;
    color: #00a1ed;
}
.portal-card-content p{
    color: #444444;
    margin: 0px 0px 15px 0px!important;
}

@media screen and (max-width:768px){
    .portal-shape{
        display: none;
    }
    .portal-card{
        margin-top: 100px;
        width: 100%;
    }
    .portal-card-wrapper{
        margin-top:120px ;
    }
    .portal-card img{
        top: -120px;
    }
    .portal-card-content{
        margin-top: 100px;
    }
}
.content-wrapper{
    min-height: 100vh;
    /* border:1px solid black; */
    margin-left: 250px;
    padding: 100px 60px 15px 30px;
    /* background-color: #f5f5f5; */
    background-color: #f8f8f8;
    transition: all 0.3s ease-in-out;
}
.content-wrapper-expand{
    min-height: 100vh;
    /* border:1px solid black; */
    margin-left: 80px;
    padding: 100px 30px 15px 30px;
    /* background-color: #f5f5f5; */
    background-color: #f8f8f8;
    transition: all 0.3s ease-in-out;
}

.toast-wrapper{
    border-radius: 50px!important;
    background-color: #C7EBFA!important;
    text-align:center;
    padding:0!important;
    width:100!important
}
.toast-text{
    color:#00A1ED!important;
    font-size: 16px;
    margin-left: 100px;
    font-weight: bold;
    padding:0!important

}

.hamburger .line{
    width: 30px;
    height: 4px;
    background-color: rgba(0, 161, 237, 0.9);
    display: block;
    margin: 4px auto;
    transition: all 0.3s ease-in-out;
  }
  
  .hamburger:hover{
    cursor: pointer;
  }
  
  /* ONE */
  
  #hamburger-1.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #hamburger-1.is-active .line:nth-child(1){
    transform: translateY(10px) rotate(45deg);
  }
  
  #hamburger-1.is-active .line:nth-child(3){
    transform: translateY(-6px) rotate(-45deg);
  }
.in-mobile-wrapper{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
}
.beranda-wrapper{
    margin-top: 10px;
}
.beranda-status-wrapper{
    width: 100%;
    /* height: 150px; */
    /* border:1px solid black; */
    display: flex;
    background: url(/static/media/status_bg.ef6d00a1.png);
    background-repeat:no-repeat;
    background-size: 100%;
}

.beranda-ilustrasi{
    width: 30%;
    position: relative;

    /* border:1px solid black */
}
.beranda-status{
    width:70%;
    /* border:1px solid black; */
    padding:10px
}
.beranda-status h2{
    font-weight: normal;
    font-size: 16px;
}
.beranda-status span{
    font-weight: bold;
}
.beranda-status h2{
    margin:5px 0px 5px 0px
}
.beranda-status p{
    font-size: 14px;
    margin:5px 0px 15px 0px

}
.beranda-grid{
    display: flex;
    /* display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px; */
}
.beranda-grid>div{
    min-height: 100px;
    background-color: white;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.35);
    padding:10px 20px 0px 20px;
    margin-right: 15px;
}
.beranda-grid>div p{
    margin: 5px 0px 5px 0px;
}

.head-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 20px; */
    /* border:1px solid black */
    margin-bottom: 20px;
}
.head-section h4{
    color: #6a7267;
    /* font-size: 24px; */
    font-weight: 600;
    line-height: 1.38;
    margin: 0;
}
.card-content{
    /* min-height: 50vh; */
    /* border-radius: 10px; */
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: white;
    
    display: flex;
    flex-direction: column;
}

.card-table{
    /* margin-top: 20px; */
    padding:20px

}
.card-table__head{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



progress {
    /* style rules */
    border-radius: 5px;
    height: 20px;
    text-align: center;
    position: relative;
  }
progress::-webkit-progress-bar {
background-color: #c3cfd5!important;
border-radius: 5px;

/* style rules */
}
progress::-webkit-progress-value {
    background-color: #00a1ed!important;
border-radius: 5px 5px 5px 5px;
/* style rules */
}
progress::-moz-progress-bar {
    background-color: #c3cfd5!important;
border-radius: 10px 10px 10px 10px;

}

progress:before {
    content: attr(data-label);
    font-size: 0.8em;
    vertical-align: 0;

    /*Position text over the progress bar */
    position:absolute;
    color: white;
    left:0;
    right:0;
    top:3px
}

.card-table__pagination{
    display: flex;
    justify-content: flex-end;
}
.icon-action{
    width:15px;
    height: 15px;
    margin:5px 2px 5px 10px;
    cursor: pointer;
}

.assign-btn{
    box-shadow: 0 3px 6px 0 rgba(0, 161, 237, 0.35)!important;
    background-color: white!important;
    width:180px!important;
    height:30px!important
}

.form-content{
    display: flex;
}
.form-informasi{
    width:43%;
    padding:20px;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    margin-right: 20px;
    background-color: white;
}
.progress-pengerjaan{
    padding:20px;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    margin-bottom: 20px;
    background-color: white;
    position: relative;
}
.daftar-peserta{
    padding:20px;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    /* margin-bottom: 20px; */
    background-color: white;

}
.field-radius{
    border-radius: 10px!important;

}
.add-proposal__field{
    width: 100%;
    margin-bottom: 15px!important;
}

.progress-wrapp{
    position: relative;
    height: 30vh;
    /* z-index: -1; */
    /* border:1px solid black; */
    /* display: flex;   
    align-items: center; */
    /* object-fit: contain;
    background-size: contain; 
    background-position: bottom center;
    background-repeat: no-repeat!important;
    background: url('../../assets/image/bg_progress.svg'); */
}

.progress-grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10px;
    z-index: 3;
    position: relative;
}

.progress-grid>div{
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    /* min-height: 100px; */
    background-color: white;
    padding:5px 15px 5px 15px
}
.progress-grid>div p{
    margin: 5px 0px 5px 0px;
}

.card-content-item-jurnal-text{
    color: #777777!important;
    margin:2px!important;
    font-size: 13px!important;
    font-weight: 600!important;
    padding:0!important
}
.card-content-item-jurnal-text-without-weight{
    color: #777777!important;
    margin:4px!important;
    font-size: 13px!important;
    font-weight: normal!important;
    padding:4px!important
}
.card-content-item-jurnal-text-without-weight-blue{
    color: #00A1ED!important;
    margin:4px!important;
    font-size: 13px!important;
    font-weight: normal!important;
    padding:4px!important
}


.tooltip-progress{
    /* width:40px;
    height: 30px; */
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    background-color: white;
    position: absolute;
    padding:5px;
    z-index: 2;
    left: 150px;
    top:-2px
}
.tooltip-progress p{
    margin:0;
    font-size: 11px;
    font-weight: bold;
    
}

.filter-container{
    /* width: 30px; */
    background-color: white;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    padding:10px;
    position: absolute;
    /* top:10px; */
    right:60px;
    z-index: 3;
}
.download-container{
    width: 250px;
    background-color: white;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    padding:10px;
    position: absolute;
    /* top:10px; */
    right:60px;
    z-index: 3;
}

.backdrop{
    width: 75%;
    position: absolute;
    height: 700px;
    z-index: 2;
    top:-10px;
    /* border:1px solid black */
}

.card-pagination{
    font-size: 14px;
}

.search::-webkit-input-placeholder{
    font-size: 10px;
}

.search:-ms-input-placeholder{
    font-size: 10px;
}

.search::-ms-input-placeholder{
    font-size: 10px;
}

.search::placeholder{
    font-size: 10px;
}
.btn-action{
    min-width:100px!important;
    margin:0px 10px 0px 10px!important;
    height: 30px!important;
}

.card-white{
    /* width:100%; */
    padding:20px;
    background-color: white;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
    /* margin-right: 20px; */
    margin-bottom: 20px;
}
.foto-section{
    display: flex;

}
.empty-circle{
    width: 60px;
    height: 60px;
    background-color: #eeeeee;
    border-radius: 100%;
    position: absolute;
    left: 0;
    /* margin-right: 30px; */
}
.ava-peserta{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    object-fit: cover;
}
.client-info{
    width: 48%;
    
}
.admin-info{
    width: 48%;

}
.audio-player:focus {
    outline: none !important;
}
.audio-player{
    height: 35px;
    /* width: 510px; */
    display: block;
    width: 100%;

}
.audio-player-empty{
    width: 100%;
}
.disable-player{
    width: 95%;
    border-radius: 20px 20px 20px 20px;
    background-color: #ebebeb;
    padding: 7px 7px 7px 15px;
    display: flex;
}
.disable-player p{
    margin: 0;
    font-weight: 500;
}
@media screen and (max-width:768px){
    .audio-player{
        width: 100%;
        display: block;

    }
    .disable-player{
        width: 98%;
    }
}

.beranda-info{
    width: 100%;
    height: 200px;
    background: url(/static/media/beranda-info.3e5628f3.png);
    background-repeat: no-repeat;
    background-size:100% 100%;
    position: relative;
    margin-top: 20px;
}
.beranda-women{
    position: absolute;
    width: 200px;
    bottom: 10px;
}

.beranda-card-container{
    display: flex;
    /* margin-left:150px; */
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-gap: 30px;
    gap: 30px;
}
.beranda-card{
    width: 200px;
    height: 100px;
    box-shadow: 0 5px 6px 0 rgba(51, 51, 51, 0.2);
    background-color: white;
    padding: 20px;
    color: #333333;
}
.beranda-card h2{
    margin:0;
    margin-bottom: 20px;
    /* margin-top: 0; */
}
.beranda-card h1{
    margin: 0;
}
.beranda-card img{
    width: 50px;
}
.total-card{
    width: 150px;
    height: 80px;
    box-shadow: 0 5px 6px 0 rgba(51, 51, 51, 0.2);
    background-color: white;
    padding: 20px;
    color: #333333;
}
.total-card h3{
    margin:0;
    margin-bottom: 20px;
    /* margin-top: 0; */
}
.total-card h1{
    margin: 0;
}
.total-card img{
    width: 40px;
}
.progress-bar{
    margin-top: 30px;
}
/* .progress-title p{
    color: white;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}
.bar{
    width: 100%;
    height: 13px;
    margin-top: 10px;
    background-color: #9b003c!important;
    border-radius: 10px;
} */
/* .bar-desktop{
    width: 100%;
    height: 10px;
    margin-top: 10px;
    background-color: #9b003c!important;
    border-radius: 10px;
} */
.bar-dtra{
    width: 100%;
    height: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: #00a0ec!important;
    border-radius: 10px;
}
/* progress {
    border-radius: 10px;
  }
  progress::-webkit-progress-bar {
    background-color: white!important;
    border-radius: 10px;
  }
  progress::-webkit-progress-value {
    background-color: #9b003c!important;
    border-radius: 10px 10px 10px 10px;
  }
  progress::-moz-progress-bar {
    background-color: #9b003c!important;
    border-radius: 10px 10px 10px 10px;

  } */
  .bar-dtra::-webkit-progress-bar {
    background-color: #e1f3fa!important;
    border-radius: 10px;

  }
  .bar-dtra{
      margin:0
  }
  .bar-dtra::-webkit-progress-value {
    background-color: #00a0ec!important;
    border-radius: 10px 10px 10px 10px;
  }
  .bar-dtra::-moz-progress-bar {
    background-color: #00a0ec!important;
    border-radius: 10px 10px 10px 10px;

  }
  .help{
    width: 28px;
    height: 20px;
    background-color: #00a1ed;
    color: white;
    font-family: 'Lato', sans-serif;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
}
.help-wrapper{
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    width: 400px;
    /* border:1px solid black; */
    /* height: 100px; */
    padding: 10px;
    right: 30px;
    top: 50px;
    z-index: 2;
}
.help-wrapper p{
    font-size: 12px;
}
.help-backdrop{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.help-mobile{
    padding:10px ;
    /* height: 100px; */
    /* border:1px solid black; */
}
.help-mobile p{
    font-size: 14px;
    font-weight: 600;
}

.spex2-option-item{
    width: 100%;
    /* padding: 10px; */
    background-color: rgba(204, 236, 251, 0.5);
    cursor: pointer;
    margin-bottom: 20px;
}
.spex2-option-item p{
    margin: 0;
}
.selected{
    background-color: #00A1ED;
}
.selected p{
    color: white;

}

.dtra-option-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box{
    box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.2);
    width: 25%;
    padding: 20px;
}
.report-wrapper{
    width: 75%;
    margin:auto;
    font-family: 'Ibarra Real Nova', serif;
}
.report-wrapper p{
    font-family: 'Lato', sans-serif;
    color: #464646;
}
body{
    -webkit-print-color-adjust: exact;
}
.pagebreak { 
    margin-top: 100px;
}
@media print 
{
    @page {
      size: A4; /* DIN A4 standard, Europe */
      margin:0;
    }
    
    .report-wrapper{
        width: 100%;

    }
    
    .report-header,.report-footer{
        width: 100%!important;
        /* z-index: 1030; */
        /* -webkit-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
        /* position: fixed; */
    }
    .report-header{
        margin-bottom: 250px;

    }
    .pagebreak { page-break-before: always; }
    .report-h1-title{
        font-size: 40px!important;
        color: #00a1ed;
    }
    .report-shape4{
        /* height: 40px!important; */
        /* border:1px solid black */
    }
    
}
.report-shape{
    margin: 0px 0px 0px auto;
    width: 600px;
    height: 40px;
    background-image: linear-gradient(to right, #00a1ed, #9b013c);
    border-radius: 0px 0px 0px 100px;
}
.report-shape2{
    margin: 0px 0px 0px auto;
    width: 600px;
    height: 40px;
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    border-radius: 0px 0px 0px 100px;
}
.report-shape3{
    margin: 0px auto 0px 0px;
    width: 95%;
    /* height: 40px; */
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    /* border-radius: 0px 0px 0px 100px; */
}
.report-shape3 h1{
    font-size: 22px;
    color: white;
    padding: 10px 10px 10px 50px;
}
.report-shape4{
    margin: 0px auto 0px 0px;
    width: 100%;
    /* height: 40px; */
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    /* border-radius: 0px 0px 0px 100px; */
}
.report-shape4 h1{
    font-size: 22px;
    color: white;
    padding: 10px 10px 10px 50px;
}
.report-h1-title{
    font-size: 70px;
    color: #00a1ed;
}

.vertical-title{
    color: rgba(155, 1, 60, 0.05);
    font-size: 80px;
    transform: rotate(-90deg);
    position: absolute;
    right: -220px;
    top: 120px;
}
.vertical-text{
    transform: rotate(-90deg);
}
.report-title{
    position: relative;

    /* border:1px solid black; */
    min-height: 150px;
}

.report-information p{
    font-size: 18px;
    /* font-weight: bold; */
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif;
    color: #464646; 
    font-size: 16px;
}
.text-bold{
    font-weight: bold!important;
}
.report-map{
    text-align: center;
}
.report-map img{
    width: 90%;
}
.report-header{
    position: fixed;
    top:0;
    width: 75%;
    /* border:1px solid black; */
    background-color: white;
    z-index: 1;
    margin-bottom: 100px;
}
.report-footer{
    position: fixed;
    bottom:0;
    width: 75%;
    height: 50px;
    background-color: white;
    z-index: 1;
}
.report-footer p{
    font-size: 18px;
    font-weight: bold;
    margin:0;
    font-family: 'Lato', sans-serif;
}

.page2{
    margin-top: 100px;
}

.div-margin{
    width: 90%;
    margin: auto;
}
.div-margin2{
    width: 90%;
    margin: auto;
}
.strategic-text p{
    color:#464646;
    font-size:14px;
    font-family: "Lato",sans-serif;
    /* font-weight: 600; */
}
.pemetaan p{
    margin:10px
}
.div-table p{
    font-size: 14px;
    font-weight: normal;
}
.border-bottom-table{
    border-bottom: 1px solid #eeeeee;
}
.header-space, .footer-space{
    height: 110px;
  }

  .content{
    width: 100%!important;
    
}
.lps-flex{
    width: 100%;
    display:grid;
    grid-template-columns: repeat( 2,1fr);
    grid-row-gap:10
}
.form-80{
    width:80%
}
  @media screen and (max-width:768px){
    .report-header{
        width: 100%;
        /* border:1px solid black; */
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .report-wrapper{
        width: 100%;
    }
    .content{
        margin-top: 20px;
    }
    .report-footer{
        width: 100%;
    }
    .report-shape{
        width: 300px;
        /* border:1px solid black */
    }
    .lps-flex{
        grid-template-columns: repeat( 1,1fr);
    grid-row-gap:3

    }
    .form-80{
        width:100%
    }
    .box{
        box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.2);
        width: 100%;
        padding: 0px;
    }
    .help{
        width: 27px;
        height: 27px;
    }
  }
